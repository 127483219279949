import type { IBox, IBoxMetadata, IBoxTShirtColor, IBoxTShirtSize } from './box.interface'
import type { IProduct } from './product.interface'
import type { IUser } from './user.interface'

export enum PaymentMethod {
	CARD = 'card',
	PIX = 'pix',
}

export enum OrderStatus {
	PENDING = 'pending',
	PAID = 'paid',
	CANCELED = 'canceled',
	FAILED = 'failed',
}

export enum OrderShipmentStatus {
	ASSEMBLING_BOX = 'assembling_box',
	SHIPPING_BOX = 'shipping_box',
	SHIPPED = 'shipped',
	DELIVERED = 'delivered',
}

export enum OrderType {
	BOX = 'box',
	CUSTOM_ITEM = 'custom_item',
}

export enum Currency {
	REAL = 'brl',
	DOLLAR = 'usd',
}

export interface IOrderItem {
	_id: string
	entity: IProduct | IBox | string
	entityType: OrderType
	streamer: IUser | string
	quantity: number
	subtotal: number
	commissionPercentage: number
	streamerCommission: number
	metadata?: {
		tShirtSize?: IBoxTShirtSize
		tShirtColor?: IBoxTShirtColor
	}
}

export interface IOrder extends Document {
	_id: string
	user: string | IUser
	discount: number
	discountPercentage: number
	subtotal: number
	streamer: string | IUser
	total: number
	totalCommission: number
	gatewayFee: number
	currency: 'brl' | 'usd'
	paymentMethod: 'card' | 'pix'
	reviewed: boolean
	gatewayId: string
	items: IOrderItem[]
	address: IUser['address']
	orderStatus: OrderStatus
	shipmentStatus: OrderShipmentStatus
	discountGatewayId?: string
	discountCode?: string
	customer: {
		name: string
		email: string
		phone: string
	}
	delivery: {
		courier: string
		trackingCode: string
		price: number
		deliveredAt: Date
	}
	createdAt: Date
}
